.btnEditCountrySettingsWrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: unset;
    _border: 2px solid blueviolet;
    width: 100%;
}

.btnEditCountrySettings{
    z-index: 999;
    _border: 2px solid red;
    width: fit-content;
    text-align: right;
}
.spinner2{
    min-width: 487px;
    min-height: 300px;
    padding-top: 80px;
}

.moduleContainer{
    flex:90%;
}
.sectionContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start ;
    max-width: 100%;
    margin-right: 2rem;
    border-radius: 0.5rem;
    min-width: 80%;
    padding: 1rem;
}
.module {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem 0;
    min-width: 90%;
}
.titles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0.5rem;
}
.undertitle {
    margin:0 0 3rem 0.5rem;
    color:rgba(0, 0, 0, 0.7);
}
.inputsContainer {
    display: flex;
}
.optionContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 25vw;
    padding: 0.25rem;
}
.optionLabel {
    font-weight: bold;
    font-size: 0.9rem;
}
.tooltipContainer {
    margin-left: 0.5rem;
}
.userContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 30%;
    border-radius: 0.25rem;
}
.userEntryContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 3rem;
    font-weight: light;
    padding:  1rem;
    font-size: 0.75rem;
}
.hideShow {
    display: flex;
    flex-direction: column;
}
.userLabel {
    color:rgba(0, 0, 0, 0.7);
}
.userInput {
    min-width: 15rem;
    min-height: 2rem;
    margin-top: 0.25rem;
    color: rgb(116, 113, 113);
    font-weight: bold;
    border: 1px solid rgba(116, 113, 113, 0.5);;
    border-radius: 0.25rem;
}
.radioButton {
    margin: 0.5rem 0.5rem;
    padding: 0.25rem 0;
    min-width: 2rem;
}
.subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.5rem;
    justify-content: space-between;
    min-width: 25vw;
}