.btnCopyWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.resultsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    max-width: 100vw;
}.results {
    min-width: 60vw;
    height: auto;
    background-color: white;
    border-radius: 0.25rem;
}
.resultsTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 0.25rem;
    color: white;
}
.resultsModule {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: space-around;
    padding: 2rem;
    min-width: 80vw;
}
.divider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: space-around;
    padding: 2rem;
    max-width: 70vw;
}
.optionsSelected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 0.25rem;
    padding: 2rem;
    min-width: 50%;
}
.inputsResults {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 50%;
    padding: 2rem;
    background-color: orange;
}
.separator {
    border-bottom: 1px solid gray;
    padding: 1rem;
    margin-bottom: 2rem;
    min-width: 90%;
}
.summary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid lightgray;
    min-width: 70%;
    min-height: 10rem;
    margin-top: 0.5rem;
    
}
.optionResult {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.result {
    background-color: transparent;
}
.area {
    margin: 0.5rem 1rem;
    min-width: 80%;
    min-height: 10rem;
    border-radius: 0.25rem;
    border: 1px solid gray;
    background-color: transparent;
    color: white;
}
h3 {
    margin: 0.5rem 0;
}
