.moduleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 5vh;
    padding: 0.5rem 1rem;
    border-bottom: 2px solid grey;
  }

