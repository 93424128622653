.moduleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 80vw;
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 100%;
  border-radius: 0.5rem;
  min-width: 80%;
  padding: 1rem;
}

.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.5rem 0;
  min-width: 90%;
}

.userEntryProductPriceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 3rem;
  font-weight: light;
  padding: 1rem;
  font-size: 0.75rem;
}

.hideShow {
  display: flex;
  flex-direction: column;
}

.subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100%;
}

.subtitle1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 100%;
}

.mainTitle {
  font-size: 1.25rem;
}

.title {
  display: inline;
  padding-bottom: 0.25rem;
  max-height: auto;
  margin: 0.5rem 0.5rem;
  color: black;
}

.inputsContainer {
  display: flex;
}

.optionContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 50vw;
  padding: 0.25rem;
}

.optionLabel {
  font-weight: bold;
  font-size: 0.9rem;
}

.tooltipContainer {
  margin-left: 0.5rem;
}

.userContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 30%;
  border-radius: 0.25rem;
}

.userEntryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-weight: light;
  padding: 1rem;
  font-size: 0.75rem;
}

.userLabel {
  color: rgba(0, 0, 0, 0.7);
}

.userInput {
  min-width: 15rem;
  min-height: 2rem;
  margin-top: 0.25rem;
  color: rgb(116, 113, 113);
  font-weight: bold;
  border: 1px solid rgba(116, 113, 113, 0.5);
  border-radius: 0.25rem;
}

.userFreeText {
  min-width: 50vw;
  min-height: 8rem;
  margin-top: 0.25rem;
  color: rgb(116, 113, 113);
  font-weight: bold;
  border: 1px solid rgba(116, 113, 113, 0.5);
  border-radius: 0.25rem;
}

.radioButton {
  margin: 0.5rem 0.5rem;
  padding: 0.25rem 0;
  min-width: 2rem;
}

.separator {
  border-bottom: 1px solid lightgray;
  padding: 1rem;
  margin-bottom: 1rem;
  min-width: 100%;
}

.moduleProductPriceContainer{
  flex:90%;
}