
.actualSettingsWrapper{
    border-left: 1px solid lightgray;
    padding: 50px;
}


.formEditCountrySettings{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cardECS {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 50px;
}
.titleECS{
    font-size: 1.25rem;
    font-weight: bold;
}
.userContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 30%;
    border-radius: 0.25rem;
}
.userEntryContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 3rem;
    font-weight: light;
    padding:  1rem;
    font-size: 0.75rem;
}
.userLabel {
    color:rgba(0, 0, 0, 0.7);
}
.userInput {
    min-width: 15rem;
    min-height: 2rem;
    margin-top: 0.25rem;
    color: rgb(116, 113, 113);
    font-weight: bold;
    border: 1px solid rgba(116, 113, 113, 0.5);;
    border-radius: 0.25rem;
}