.mainContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 80vw;
}
.containerForm{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100%;
}
.separator {
    border-bottom: 1px solid lightgray;
    padding: 1rem;
    margin-bottom: 1rem;
    min-width: 100%;
}
