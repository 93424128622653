.errorWrap {
  margin: 8rem 4rem;
}
.spinner {
  margin-top: 10rem;
}

.not-logged-in-warning-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 40vh;
  justify-content: space-around;
}
