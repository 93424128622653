
.title {
  color: black;
  font-size: 2.5rem;
}
.main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100vw;
  margin: 0;
  padding: 1rem;
}

.column {
  width: 400px;
}
.lang-edit-wrapper {
  border: 1px solid lightgray;
  margin: 2rem;
  padding: 2rem;
  animation: fadeInAnimation ease 3s
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}
.userContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 30%;
  border-radius: 0.25rem;
}
.userEntryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 3rem;
  font-weight: light;
  padding:  1rem;
  font-size: 0.75rem;
}
.userLabel {
  color:rgba(0, 0, 0, 0.7);
}
.userInput {
  min-width: 15rem;
  min-height: 2rem;
  margin-top: 0.25rem;
  color: rgb(116, 113, 113);
  font-weight: bold;
  border: 1px solid rgba(116, 113, 113, 0.5);;
  border-radius: 0.25rem;
}
.text-area textarea{
  min-width: 40em;
}